import React from "react";
import { Link } from "react-router-dom";

function Project({ data })
{
  const { title, description, role, duration, imageSrc, path } = data;

  return (
    <article className="project_cover w2k">
      <header>
        <h2>{description}</h2>
      </header>
      <p>Role: {role}</p>
      <p>Duration: {duration}</p>

      <Link to={path}>
        <img className="mh75" src={imageSrc} alt={title} />
      </Link>

      <div className="project_link">
        <Link to={path}>
          <h3>View case study</h3>
          <svg
            className="view_case_study_arrow"
            width="20"
            height="19"
            fill="#150115"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M18 1.62695V0.376953H19.25V1.62695H18ZM2.88388
              18.5108C2.39573 18.999 1.60427 18.999 1.11612
              18.5108C0.627962 18.0227 0.627962 17.2312 1.11612
              16.7431L2.88388 18.5108ZM16.75 14.427V1.62695H19.25V14.427H16.75ZM18
              2.87695H5.2V0.376953H18V2.87695ZM18.8839 2.51084L2.88388
              18.5108L1.11612 16.7431L17.1161 0.74307L18.8839 2.51084Z"
            />
          </svg>
        </Link>
      </div>
    </article>
  );
}

export default Project;
