import React from "react";
import ProjectsData from "../home/ProjectsData";

function GeneralInformation({ projectIndex })
{
  const { title, description, role, duration, tools, imageSrc } = ProjectsData[projectIndex];

  return (
    <section className="general_information">
      <h1>{title}</h1>
      <h2>{description}</h2>
      <p>Role: {role}</p>
      <p>Duration: {duration}</p>
      <p>Tools: {tools}</p>
      <img src={imageSrc} alt={title} className="mh75" />
    </section>
  );
}

export default GeneralInformation;
