import React from "react";

function ColorPurse({ fillColor, fontColor = "#FFFCFC" })
{
  fillColor = fillColor.toUpperCase();

  return (
    <div className="mango_color_purse">
      <svg
        width="267"
        height="270"
        viewBox="0 0 267 270"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M16.3034 52.3731C16.6316 47.9553 20.311 44.5386 24.7403
        44.5386H242.213C246.642 44.5386 250.321 47.9553 250.65
        52.3731L266.048 259.681C266.413 264.588 262.531 268.769
        257.611 268.769H9.34148C4.42213 268.769 0.540118 264.588 0.90458
        259.681L16.3034 52.3731Z"
          fill={fillColor} stroke={fillColor}
        />

        <path d="M175.779 74.1537C175.779 75.3218 176.726 76.2691 177.894
        76.2691C179.061 76.2691 180.009 75.3218 180.009 74.1537H175.779ZM86.9473
        74.1537C86.9473 75.3218 87.8944 76.2691 89.0623 76.2691C90.2302 76.2691
        91.1773 75.3218 91.1773 74.1537H86.9473ZM110.213
        4.346H156.743V0.115234H110.213V4.346ZM175.779
        23.3845V74.1537H180.009V23.3845H175.779ZM91.1773
        74.1537V23.3845H86.9473V74.1537H91.1773ZM156.743 4.346C167.256 4.346
        175.779 12.8698 175.779 23.3845H180.009C180.009 10.5332 169.593 0.115234
        156.743 0.115234V4.346ZM110.213 0.115234C97.3634 0.115234 86.9473 10.5332
        86.9473 23.3845H91.1773C91.1773 12.8698 99.6996 4.346 110.213 4.346V0.115234Z"
          fill={fillColor} />
      </svg>
      <p style={{ color: fontColor }}>{fillColor}</p>
    </div>
  );
}

export default ColorPurse;
