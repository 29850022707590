import React, { useEffect } from "react";

function Hi()
{
  const scrollToProjects = () =>
  {
    const projectsH1Element = document.getElementById("home_projects");
    const topHeaderElement = document.getElementById("header_menu");

    const projectsH1ElementBounds = projectsH1Element.getBoundingClientRect();
    const topHeaderElementBounds = topHeaderElement.getBoundingClientRect();

    const offsetY = projectsH1ElementBounds.y - topHeaderElementBounds.height;

    window.scrollBy({ top: offsetY, behavior: "smooth" });
  }

  useEffect(() =>
  {
    const projectsH1Element = document.getElementById("home_projects");
    const andreaIntroductionTextElement = document.getElementById("andrea_introduction_text");
    const jumpToProjectsButtonElement = document.getElementById("jump_to_projects_button");

    const dyThreshold = 220;

    const handleResize = () =>
    {
      const projectsH1StartY = projectsH1Element.getBoundingClientRect().top;
      const andreaIntroductionTextEndY = andreaIntroductionTextElement.getBoundingClientRect().bottom;

      const dy = projectsH1StartY - andreaIntroductionTextEndY;

      // console.log(dy);

      if (dy < dyThreshold)
      {
        jumpToProjectsButtonElement.classList.add("hide");
        return;
      }

      jumpToProjectsButtonElement.classList.remove("hide");
    };

    handleResize();

    // console.log(">>>>> added handleResize");
    window.addEventListener("resize", handleResize, true);

    return () =>
    {
      // console.log("<<<<< removed handleResize");
      window.removeEventListener("resize", handleResize, true);
    };
  });

  return (
    <section id="hi">
      <h1>Hi, I am Andrea</h1>
      <h2>UI & UX designer based in Vienna</h2>
      <p id="andrea_introduction_text">
        What draws me to UI & UX is its
        major role in finding creative and practical solutions for user problems
        and revealing new opportunities to them. I'm passionate about human-centered
        design, which makes the users satisfied and my clients happy.
      </p>
      <button id="jump_to_projects_button" className="circle_jump_button" onClick={scrollToProjects}>
        <svg
          width="48"
          height="26"
          fill="#FFF"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="
            M45.1717 0.858398L25.4137 20.6164C25.0335 20.9814 24.5268 21.1852
            23.9997 21.1852C23.4726 21.1852 22.966 20.9814 22.5857
            20.6164L2.83972 0.868399L0.0117188 3.6964L19.7577 23.4444C20.9014
            24.5344 22.4208 25.1425 24.0007 25.1425C25.5807 25.1425 27.1
            24.5344 28.2437 23.4444L47.9997 3.6864L45.1717 0.858398Z"
          />
        </svg>
      </button>
    </section>
  );
}

export default Hi;
