import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import andrea_resume from "./docs/Andrea_Jeremic_CV_en.pdf";

function Header()
{
  useEffect(() =>
  {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() =>
  {
    const doc = document.documentElement;
    const headerMenu = document.getElementById("header_menu");
    const headerMenuHideThresholdY = 500;
    const headerMenuShowThresholdY = -10;

    let prevScrollY = window.scrollY || doc.scrollTop;

    const handleScroll = () =>
    {
      const curScrollY = window.scrollY || doc.scrollTop;
      const dy = curScrollY - prevScrollY;
      prevScrollY = curScrollY;

      // console.log(`curScrollY=${curScrollY}`);
      // console.log(dy);

      if (curScrollY > headerMenuHideThresholdY && dy > 0)
      {
        headerMenu.classList.add("hide");
        return;
      }

      if (dy < headerMenuShowThresholdY)
      {
        headerMenu.classList.remove("hide");
      }
    };

    // console.log(">>>>> Header scroll listener added");
    window.addEventListener("scroll", handleScroll)

    return () =>
    {
      // console.log("<<<<< Header scroll listener removed");
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() =>
  {
    const toggleMenuCheckbox = document.getElementById("toggle_menu_checkbox");
    const headerMenu = document.getElementById("header_menu");
    const andreaLogo = document.getElementById("andrea_logo");
    const headerMenuLinks = document.getElementById("header_menu_links");

    const toggleMenuHandler = () =>
    {
      // console.log("toggleMenuHandler");

      if (toggleMenuCheckbox.checked)
      {
        document.body.classList.add("disable_scroll");
        headerMenu.classList.add("full");
        andreaLogo.classList.add("white");
        headerMenuLinks.classList.add("links_visible");
        return;
      }

      document.body.classList.remove("disable_scroll");
      headerMenu.classList.remove("full");
      andreaLogo.classList.remove("white");
      headerMenuLinks.classList.remove("links_visible");
    };

    const clickToggleMenuCheckbox = () =>
    {
      if (toggleMenuCheckbox.checked)
      {
        toggleMenuCheckbox.click();
      }
    };

    toggleMenuHandler();

    // console.log(">>>>> Toggle header listener added");
    toggleMenuCheckbox.addEventListener("change", toggleMenuHandler);
    headerMenuLinks.firstChild.addEventListener("click", clickToggleMenuCheckbox);
    andreaLogo.addEventListener("click", clickToggleMenuCheckbox);

    return () =>
    {
      // console.log("<<<<< Toggle header listener removed");
      toggleMenuCheckbox.removeEventListener("change", toggleMenuHandler);
      headerMenuLinks.firstChild.removeEventListener("click", clickToggleMenuCheckbox);
      andreaLogo.removeEventListener("click", clickToggleMenuCheckbox);
    };
  }, []);

  return (
    <header id="header_menu">
      <nav>
        <Link to="/" id="andrea_logo">andrea.</Link>

        <div id="toggle_menu">
          <input id="toggle_menu_checkbox" type="checkbox" />
          <label id="burger_wrapper" htmlFor="toggle_menu_checkbox">
            <div id="burger"></div>
          </label>
        </div>

        <div id="header_menu_links">
          <Link to="/">Projects</Link>
          <Link to={andrea_resume} target="blank">Resume</Link>
          <a href="mailto:kandrea.andrea@gmail.com">Contact me</a>
        </div>
      </nav>
    </header>
  );
}

export default Header;
