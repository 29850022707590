import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import ProjectsData from "../home/ProjectsData";

function ProjectsCarousel({ projectIndex })
{
  const projectCount = ProjectsData.length;

  const previousProjectPath = ProjectsData[(projectCount + projectIndex - 1) % projectCount].path;
  const nextProjectPath = ProjectsData[(projectIndex + 1) % projectCount].path;

  const touchStartX = useRef(null);

  useEffect(() =>
  {
    // console.log("useEffect start");

    const onTouchStart = (e) =>
    {
      // console.log("onTouchStart");
      touchStartX.current = (e.touches.length === 1) ? e.targetTouches[0].clientX : null;
    }

    const onTouchEnd = (e) =>
    {
      // console.log("onTouchEnd");

      const startX = touchStartX.current;
      const endX = e.changedTouches[0].clientX;

      touchStartX.current = null;

      const distance = endX - startX;

      if (startX === null || isNaN(distance))
      {
        return;
      }

      const minSwipeDistance = 120;

      const isLeftSwipe = (distance > minSwipeDistance);
      const isRightSwipe = (distance < -minSwipeDistance);

      if (isLeftSwipe)
      {
        document.getElementById("previous_project_link").click();
        return;
      }

      if (isRightSwipe)
      {
        document.getElementById("next_project_link").click();
        return;
      }
    }

    const rootElement = document.getElementById("root");
    rootElement.addEventListener("touchstart", onTouchStart);
    rootElement.addEventListener("touchend", onTouchEnd);

    const keyboardNavigation = (e) =>
    {
      const keyCode = e.code;
      // console.log(`Pressed: ${keyCode}`);

      if (keyCode === "ArrowLeft")
      {
        document.getElementById("previous_project_link").click();
        return;
      }

      if (keyCode === "ArrowRight")
      {
        document.getElementById("next_project_link").click();
        return;
      }
    };

    document.addEventListener("keyup", keyboardNavigation);

    // console.log("useEffect end");

    return () =>
    {
      rootElement.removeEventListener("touchstart", onTouchStart);
      rootElement.removeEventListener("touchend", onTouchEnd);
      document.removeEventListener("keyup", keyboardNavigation);
    };

  }, [])

  return (
    <section id="projects_carousel">

      <Link to={previousProjectPath} id="previous_project_link">
        <svg
          width="9"
          height="15"
          viewBox="0 0 9 15"
          fill="#B030B0"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M6.77506 14.1757L1.48206 8.88272C1.10712 8.50766 0.896484
          7.99905 0.896484 7.46872C0.896484 6.93839 1.10712 6.42977 1.48206
          6.05472L6.77506 0.761719L8.18906 2.17572L2.90006 7.46872L8.19306
          12.7617L6.77506 14.1757Z"
          />
        </svg>
        Previous project
      </Link>


      <Link to={nextProjectPath} id="next_project_link">
        Next project
        <svg
          width="8"
          height="15"
          viewBox="0 0 8 15"
          fill="#B030B0"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M1.81139 14.1757L0.400391 12.7617L5.68938 7.46872L0.400391
          2.17572L1.81539 0.761719L7.10038 6.05472C7.47532 6.42977 7.68595
          6.93839 7.68595 7.46872C7.68595 7.99905 7.47532 8.50766 7.10038
          8.88272L1.81139 14.1757Z"
          />
        </svg>
      </Link>

    </section>
  );
}

export default ProjectsCarousel;
