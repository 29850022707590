import React from "react";
import Header from "../../Header";
import GeneralInformation from "../GeneralInformation";
import ProjectOverview from "../ProjectOverview";
import ProblemStatement from "../ProblemStatement";
import logo_01 from "../../images/05_captured/logo_01.webp";
import logo_02 from "../../images/05_captured/logo_02.webp";
import StyleColor from "./StyleColor";
import ui_components from "../../images/05_captured/ui_components.webp";
import captured_video from "../../images/05_captured/captured.mp4";
import image_overview_desktop from "../../images/05_captured/image_overview_desktop.webp";
import image_overview_mobile from "../../images/05_captured/image_overview_mobile.webp";
import image_overview_tablet from "../../images/05_captured/image_overview_tablet.webp";
import search_mobile_01 from "../../images/05_captured/search_mobile_01.webp";
import search_mobile_02 from "../../images/05_captured/search_mobile_02.webp";
import search_mobile_03 from "../../images/05_captured/search_mobile_03.webp";
import search_mobile_04 from "../../images/05_captured/search_mobile_04.webp";
import search_mobile_05 from "../../images/05_captured/search_mobile_05.webp";
import search_images from "../../images/05_captured/search_images.webp";
import voice_search from "../../images/05_captured/voice_search.mp4";
import visual_search from "../../images/05_captured/visual_search.mp4";
import upload_images_desktop from "../../images/05_captured/upload_images_desktop.webp";
import upload_images_mobile from "../../images/05_captured/upload_images_mobile.webp";
import downloads_tablet from "../../images/05_captured/downloads_tablet.webp";
import collection_mobile_01 from "../../images/05_captured/collection_mobile_01.webp";
import collection_mobile_02 from "../../images/05_captured/collection_mobile_02.webp";
import collection_mobile_03 from "../../images/05_captured/collection_mobile_03.webp";
import collection_desktop from "../../images/05_captured/collection_desktop.webp";
import ProjectsCarousel from "../ProjectsCarousel";
import Footer from "../../Footer";

function Captured({ projectIndex })
{
  const aboutText = "Captured is an image library. The platform " +
    "allows users to upload, share, save, collect and download images.";

  const responsibilitiesText = "Paper and digital wire framing, low and high-fidelity " +
    "prototyping, accounting for accessibility, and iterating on designs.";

  const problemText = "Companies and brands often don't have enough budget and " +
    "time to hire a professional photographer for photo shoots.";

  const solutionText = "Providing a time-saving and cost-efficient " +
    "way to gather instantly downloadable creative assets.";

  return (
    <>
      <Header />
      <main>
        <GeneralInformation projectIndex={projectIndex} />
        <ProjectOverview aboutText={aboutText} responsibilitiesText={responsibilitiesText} />
        <ProblemStatement problemText={problemText} solutionText={solutionText} />

        <section className="captured_style_guide">
          <h1>Style guide</h1>

          <div>
            <h2>Logo design</h2>
            <div className="resp_01">
              <img src={logo_01} className="mh85" alt="Logo" />
              <img src={logo_02} className="mh85" alt="Logo" />
            </div>
          </div>

          <div>
            <h2>Typography Roboto</h2>
            <div className="captured_style_guide_typography">
              <div><p>Aa</p></div>
              <div>
                <p>The brown fox jumped over the lazy dog.</p>
                <p>abc def ghi jkl mno pqrs tuv wxyz</p>
                <p>ABC DEF GHI JKL MNO PQRS TUV WXYZ</p>
              </div>
            </div>
          </div>

          <div className="captured_style_guide_colors">
            <div>
              <h2>Accent colors</h2>
              <div>
                <StyleColor name="Accent color pink 500" code="#960BA2" />
                <StyleColor name="Accent color pink 700" code="#6D0F75" />
                <StyleColor name="Accent color blue 500" code="#9C99FF" />
                <StyleColor name="Accent color blue 700" code="#17148D" />
              </div>
            </div>
            <div>
              <h2>Primary colors</h2>
              <div>
                <StyleColor name="Background color" code="#24272D" />
                <StyleColor name="Dark on surface" code="#1E2229" />
                <StyleColor name="Bright on surface" code="#373E49" />
              </div>
            </div>
            <div>
              <h2>Neutral colors</h2>
              <div>
                <StyleColor name="Black" code="#000000" />
                <StyleColor name="White" code="#FFFFFF" />
                <StyleColor name="Gray 50" code="#EBEBEB" />
                <StyleColor name="Gray 100" code="#DFDFDF" />
                <StyleColor name="Gray 300" code="#CCCCCC" />
                <StyleColor name="Gray 500" code="#A8ADAD" />
              </div>
            </div>
            <div>
              <h2>Semantic colors</h2>
              <div>
                <StyleColor name="Error red 500" code="#EB2222" />
                <StyleColor name="Error red 700" code="#B20000" />
                <StyleColor name="Success green 500" code="#0CBC8B" />
                <StyleColor name="Success green 700" code="#119772" />
              </div>
            </div>
          </div>

          <h2>UI components</h2>
          <img src={ui_components} className="mh85" alt="UI Components" />
        </section>

        <section>
          <h1>Home page - navigating video</h1>
          <video autoPlay loop muted className="mh85">
            <source src={captured_video} type="video/mp4" />
          </video>
        </section>

        <section>
          <h1>Image overview</h1>

          <h2>Getting additional information about the image</h2>
          <p className="max_width_720px">
            Clicking or tapping on the images opens the 'Image overview' where users
            can view the images in a higher resolution and read additional information.
            A list of related images is also shown to enhance the search functionality.
          </p>
          <img src={image_overview_desktop} alt="Images overview desktop" />
          <div id="captured_image_overview_devices" className="resp_01">
            <img src={image_overview_mobile} alt="Images overview mobile" />
            <img src={image_overview_tablet} alt="Images overview tablet" />
          </div>
        </section>

        <section>
          <h1>Searching for images has never been easier</h1>

          <h2>Searching using filters and tags</h2>
          <p className="max_width_720px">
            Users can easily adjust their search criteria using filters and popular tags.
            Filtering images: colors, tags and orientation (visual and textual description).
          </p>
          <div className="resp_01">
            <img src={search_mobile_01} className="mobile_cutout" alt="Search mobile pt.1" />
            <img src={search_mobile_02} className="mobile_cutout" alt="Search mobile pt.2" />
            <img src={search_mobile_03} className="mobile_cutout" alt="Search mobile pt.3" />
          </div>
          <div id="captured_search_images" className="resp_01">
            <img src={search_mobile_04} className="mh75" alt="Search mobile pt.4" />
            <img src={search_images} alt="Search images" />
            <img src={search_mobile_05} className="mh75" alt="Search mobile pt.5" />
          </div>
        </section>

        <section>
          <h1>Visual and voice search</h1>

          <h2>Improving accessibility</h2>
          <p className="max_width_720px">
            Having in mind the accessibility of the website, I added the visual and voice
            search features. They allow users to find exactly what they are looking for
            faster than using a text-based search.
          </p>
          <div className="resp_01">
            <video autoPlay loop muted className="mobile_cutout">
              <source src={voice_search} type="video/mp4" />
            </video>
            <video autoPlay loop muted className="mobile_cutout">
              <source src={visual_search} type="video/mp4" />
            </video>
          </div>
        </section>

        <section>
          <h1>Save, share, collect, download, upload images</h1>

          <h2>Main features</h2>
          <p className="max_width_720px">
            Collections are a key feature of Captured. Users can create them to save and
            order images. Images can also be saved individually. There is no limit to the
            number of shared or downloaded images since I believe any limitations would
            just tarnish the user experience.
          </p>

          <div id="captured_upload_images" className="resp_01">
            <img src={upload_images_desktop} alt="Upload images (desktop)" />
            <img src={upload_images_mobile} alt="Upload images (mobile)" />
          </div>

          <img src={downloads_tablet} className="mh75" alt="Downloads (tablet)" />

          <div className="resp_01">
            <img src={collection_mobile_01} className="mobile_cutout" alt="Collection mobile pt.1" />
            <img src={collection_mobile_02} className="mobile_cutout" alt="Collection mobile pt.2" />
            <img src={collection_mobile_03} className="mh75" alt="Collection mobile pt.3" />
          </div>

          <img src={collection_desktop} alt="Collection desktop" />
        </section>

        <section>
          <h1>Takeaways</h1>

          <p className="max_width_720px">
            I've really enjoyed working on this project as a UI designer, especially because of the
            great team collaboration, which led to client satisfaction. I've chosen a dark theme to
            emphasize the visual content of the website. Heavy backgrounds provide a perfect
            high-contrast backdrop for the images. The project is still ongoing with the UX designer
            running studies to improve the overall usability.
          </p>
        </section>

        <ProjectsCarousel projectIndex={projectIndex} />
      </main>
      <Footer />
    </>
  );
}

export default Captured;
