import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

function SlideShow({ id, imageArray, imageClassesArray })
{
    const imageCount = imageArray.length;
    const imageClasses = imageClassesArray.join(" ");

    useEffect(() =>
    {
        const imageNodes = document.getElementById(id).firstChild.nextSibling.childNodes;
        let currentImage = 0;

        const updateStyles = () =>
        {
            // console.log(`timeout ${currentImage}`);

            imageNodes[(currentImage + imageCount - 1) % imageCount].classList.remove("slide_show_active_left");
            imageNodes[currentImage].classList.remove("slide_show_active_right");

            imageNodes[currentImage].classList.add("slide_show_active_left");
            imageNodes[(currentImage + 1) % imageCount].classList.add("slide_show_active_right");

            currentImage = (currentImage + 1) % imageCount;
        };

        updateStyles();

        const intervalID = setInterval(updateStyles, 3000);

        return () => { clearInterval(intervalID); };
    });

    return (
        <div id={id} className="slide_show">

            <img src={imageArray[0]} alt="" className={imageClasses} />

            <div>
                {
                    imageArray.map(image =>
                    {
                        return <img src={image} key={image} alt="" className={imageClasses} />;
                    })
                }
            </div>

        </div>
    );
}

SlideShow.propTypes = {
    id: PropTypes.string.isRequired,
    imageArray: PropTypes.arrayOf(PropTypes.string).isRequired,
    imageArrayNotEmpty: (props, propName, componentName) =>
    {
        const imageArray = props.imageArray;

        if (imageArray.length > 1)
        {
            return null;
        }

        return new Error(`imageArray in ${componentName} must have elements (${propName})`);
    },
    imageClassesArray: PropTypes.arrayOf(PropTypes.string),
}

SlideShow.defaultProps = {
    imageClassesArray: [],
};

export default SlideShow;
