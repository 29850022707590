import React from "react";

function DesignStep({ imageSrc, titleText })
{
  return (
    <div>
      <img src={imageSrc} alt={titleText} />
      <h2>{titleText}</h2>
    </div>
  );
}

export default DesignStep;
