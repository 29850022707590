import React from "react";
import Footer from "../../Footer";
import Header from "../../Header";
import actions from "../../images/01_art_gallery/actions.webp";
import artworks_video from "../../images/01_art_gallery/artworks.mp4";
import artworks_01 from "../../images/01_art_gallery/artworks_01.webp";
import artworks_02 from "../../images/01_art_gallery/artworks_02.webp";
import artworks_03 from "../../images/01_art_gallery/artworks_03.webp";
import artworks_04 from "../../images/01_art_gallery/artworks_04.webp";
import artworks_05 from "../../images/01_art_gallery/artworks_05.webp";
import artworks_06 from "../../images/01_art_gallery/artworks_06.webp";
import art_gallery_hall from "../../images/01_art_gallery/art_gallery_hall.webp";
import buy_ticket_video from "../../images/01_art_gallery/buy_ticket.mp4";
import ideate_01 from "../../images/01_art_gallery/ideate_01.webp";
import ideate_02 from "../../images/01_art_gallery/ideate_02.webp";
import ideate_03 from "../../images/01_art_gallery/ideate_03.webp";
import interface_01_background from "../../images/01_art_gallery/interface_01_background.webp";
import interface_01_foreground from "../../images/01_art_gallery/interface_01_foreground.webp";
import interface_02_background from "../../images/01_art_gallery/interface_02_background.webp";
import interface_02_foreground from "../../images/01_art_gallery/interface_02_foreground.webp";
import mia from "../../images/01_art_gallery/mia.webp";
import moodboard from "../../images/01_art_gallery/moodboard.webp";
import more_screens_01 from "../../images/01_art_gallery/more_screens_01.webp";
import more_screens_02 from "../../images/01_art_gallery/more_screens_02.webp";
import more_screens_03 from "../../images/01_art_gallery/more_screens_03.webp";
import more_screens_04 from "../../images/01_art_gallery/more_screens_04.webp";
import more_screens_05 from "../../images/01_art_gallery/more_screens_05.webp";
import more_screens_06 from "../../images/01_art_gallery/more_screens_06.webp";
import more_screens_07 from "../../images/01_art_gallery/more_screens_07.webp";
import sketch_01 from "../../images/01_art_gallery/sketch_01.webp";
import sketch_02 from "../../images/01_art_gallery/sketch_02.webp";
import tickets_01 from "../../images/01_art_gallery/tickets_01.webp";
import tickets_02 from "../../images/01_art_gallery/tickets_02.webp";
import tickets_03 from "../../images/01_art_gallery/tickets_03.webp";
import virtual_tour_video from "../../images/01_art_gallery/virtual_tour.mp4";
import virtual_tours_sunset_01 from "../../images/01_art_gallery/virtual_tours_sunset_01.webp";
import virtual_tours_sunset_02 from "../../images/01_art_gallery/virtual_tours_sunset_02.webp";
import virtual_tours_sunset_03 from "../../images/01_art_gallery/virtual_tours_sunset_03.webp";
import wireframe_01 from "../../images/01_art_gallery/wireframe_01.webp";
import wireframe_02 from "../../images/01_art_gallery/wireframe_02.webp";
import wireframe_03 from "../../images/01_art_gallery/wireframe_03.webp";
import wireframe_04 from "../../images/01_art_gallery/wireframe_04.webp";
import wireframe_sequence from "../../images/01_art_gallery/wireframe_sequence.webp";
import GeneralInformation from "../GeneralInformation";
import ProjectOverview from "../ProjectOverview";
import ProjectsCarousel from "../ProjectsCarousel";
import SlideShow from "../SlideShow";

function ArtGallery({ projectIndex })
{
  const aboutText = "Although the online art market is growing around 15% each year, " +
    "many people still prefer to visit real world galleries. Art enthusiasts interact " +
    "with art in various ways, therefore my goals were to facilitate engagement between " +
    "people who share interest in art and make exhibitions and information about " +
    "artwork more accessible.";

  const responsibilitiesText = "User research, creating a persona, paper and digital wire framing, " +
    "low and high-fidelity prototyping, conducting usability studies, accounting for accessibility, " +
    "and design iterations.";

  const svgFillColor = "#AC7962";

  const moreScreensArray = [
    more_screens_01,
    more_screens_02,
    more_screens_03,
    more_screens_04,
    more_screens_05,
    more_screens_06,
    more_screens_07,
  ];

  return (
    <>
      <Header />
      <main>
        <GeneralInformation projectIndex={projectIndex} />

        <ProjectOverview aboutText={aboutText} responsibilitiesText={responsibilitiesText} />

        <section>
          <h1>Problem statement</h1>

          <h2>Problem</h2>
          <p>
            Local art galleries lack opportunities to reach
            out and attract qualified art lovers and buyers.
          </p>

          <h2>Solution</h2>
          <p>
            Designing an art gallery app for purchasing artwork, along with helpful information
            about exhibitions for art lovers. Special attention is paid to creating virtual
            exhibitions, which are a practical and cost-effective solution for the galleries.
            The goal is to overcome limitations of physical exhibitions and provide great
            interactivity to users.
          </p>
          <img id="art_gallery_hall" src={art_gallery_hall} alt="Art gallery" />
        </section>

        <section>
          <h1>Research</h1>

          <h2>Survey</h2>
          <p className="max_width_720px">
            The goal of this user research was to find out how art enthusiasts interact with
            art online and what motivates them to visit real world exhibitions. According to
            the survey, people visit exhibitions to socialize, learn more about the artwork,
            and gain inspiration for their own creations. The majority of survey respondents
            rely on digital resources to expand their knowledge about particular art exhibitions.
            The main pain points these users face are:
          </p>

          <section className="resp_01 art_gallery_pain_points">
            <div>
              <svg
                width="48"
                height="49"
                viewBox="0 0 48 37"
                fill={svgFillColor}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11 28.4834C12.6569 28.4834 14 27.1403 14 25.4834C14 23.8265 12.6569
                22.4834 11 22.4834C9.34315 22.4834 8 23.8265 8 25.4834C8 27.1403 9.34315
                28.4834 11 28.4834Z"
                />
                <path d="M42 0.483398H6C4.4087 0.483398 2.88258 1.11554 1.75736 2.24076C0.632141
                3.36598 0 4.8921 0 6.4834L0 36.4834H48V6.4834C48 4.8921 47.3679 3.36598 46.2426
                2.24076C45.1174 1.11554 43.5913 0.483398 42 0.483398ZM6 4.4834H42C42.5304 4.4834
                43.0391 4.69411 43.4142 5.06919C43.7893 5.44426 44 5.95297 44
                6.4834V10.4834H4V6.4834C4 5.95297 4.21071 5.44426 4.58579 5.06919C4.96086
                4.69411 5.46957 4.4834 6 4.4834ZM4 32.4834V14.4834H44V32.4834H4Z"
                />
              </svg>

              <h2>Pain point: money</h2>
              <ul>
                <li>Many respondents have a limited budget, and not much is left after covering living expenses</li>
                <li>Buying art online is not always safe</li>
              </ul>
            </div>

            <div>
              <svg
                width="48"
                height="49"
                viewBox="0 0 48 49"
                fill={svgFillColor}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1.02807e-07 44.4835H48V48.4835H1.02807e-07V44.4835ZM48
                16.3015V20.4835H42V36.4835H46V40.4835H2V36.4835H6V20.4835H1.02807e-07V16.3015C-0.000199409
                15.2236 0.28999 14.1655 0.840066 13.2386C1.39014 12.3116 2.17979 11.5499 3.126
                11.0335L21.126 1.21553C22.0072 0.733253 22.9955 0.480469 24 0.480469C25.0045 0.480469
                25.9929 0.733253 26.874 1.21553L44.874 11.0335C45.8202 11.5499 46.6099 12.3116 47.1599
                13.2386C47.71 14.1655 48.0002 15.2236 48 16.3015ZM10 36.4835H16V20.4835H10V36.4835ZM20
                20.4835V36.4835H28V20.4835H20ZM38 20.4835H32V36.4835H38V20.4835ZM44 16.3015C44.0001
                15.9422 43.9033 15.5895 43.72 15.2805C43.5366 14.9715 43.2734 14.7176 42.958 14.5455L24.958
                4.72753C24.6642 4.56719 24.3348 4.48316 24 4.48316C23.6653 4.48316 23.3359 4.56719 23.042
                4.72753L5.042 14.5455C4.7266 14.7176 4.46338 14.9715 4.28002 15.2805C4.09666 15.5895
                3.99993 15.9422 4 16.3015V16.4835H44V16.3015Z"
                />
              </svg>

              <h2>Pain point: accessibility</h2>
              <ul>
                <li>Many respondents are not regularly informed about the events in the local gallery</li>
                <li>Physical distance of the gallery</li>
                <li>Limited mobility due to Covid-19 restrictions</li>
              </ul>
            </div>

            <div>
              <svg
                width="48"
                height="49"
                viewBox="0 0 48 49"
                fill={svgFillColor}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M46 26.4834C45.9928 20.9981 43.9384 15.7129 40.2392 11.6626C36.54
                7.61236 31.4622 5.08859 26 4.5854V0.483398H22V4.5854C17.9086 4.951 14.0018
                6.45714 10.7238 8.93259C7.44574 11.4081 4.92795 14.7535 3.45663 18.5887C1.98531
                22.4239 1.61948 26.5949 2.40072 30.6277C3.18197 34.6604 5.07895 38.3931 7.87602
                41.4014L4.02002 45.6374L6.98002 48.3294L10.848 44.0834C14.6365 46.9427 19.2536
                48.4895 24 48.4895C28.7464 48.4895 33.3635 46.9427 37.152 44.0834L41.02
                48.3354L43.98 45.6434L40.124 41.4074C43.8998 37.355 45.9994 32.0222 46
                26.4834ZM24 44.4834C20.44 44.4834 16.9598 43.4277 13.9998 41.4499C11.0397
                39.472 8.73257 36.6608 7.37019 33.3717C6.00781 30.0826 5.65135 26.4634 6.34588
                22.9718C7.04042 19.4801 8.75475 16.2728 11.2721 13.7555C13.7894 11.2381 16.9967
                9.5238 20.4884 8.82926C23.9801 8.13473 27.5993 8.49119 30.8883 9.85357C34.1774
                11.2159 36.9886 13.523 38.9665 16.4831C40.9443 19.4432 42 22.9233 42
                26.4834C41.9942 31.2555 40.0959 35.8305 36.7215 39.2049C33.3471 42.5793 28.7721
                44.4776 24 44.4834Z"
                />
                <path d="M11.0452 3.9737L8.9112 0.595703C5.45911 2.62098 2.44184 5.30927 0.0332031
                8.5057L3.3592 10.7237C5.4545 7.99214 8.06593 5.69872 11.0452 3.9737Z"
                />
                <path d="M44.6411 10.7237L47.967 8.5057C45.5584 5.30927 42.5412 2.62098 39.0891
                0.595703L36.9551 3.9737C39.9343 5.69872 42.5458 7.99214 44.6411 10.7237Z"
                />
                <path d="M22 14.4834V25.3114L28.586 31.8974L31.414 29.0694L26 23.6554V14.4834H22Z" />
              </svg>
              <h2>Pain point: time</h2>
              <ul>
                <li>Many respondents have issues fitting gallery visits into their schedule</li>
              </ul>
            </div>
          </section>
          <section>
            <h1>Define</h1>

            <h2>Persona</h2>
            <p className="max_width_720px">
              Based on all the information I gathered throughout this research I was able to create
              a persona which represents the target audience of the Art Corner app. My persona, Mia,
              is a young art lover in a small town who needs a convenient way for discovering art
              because she wants to gain inspiration for her own creations.
            </p>

            <div id="mia" className="w2k resp_01">
              <img src={mia} alt="Mia" />
              <div>
                <h2>Mia, 22</h2>
                <h3>Hometown: Monterey, CA, USA</h3>
                <h3>Occupation: waitress at the local coffee shop</h3>
                <h3>Family: single mother</h3>
                <hr />
                <h3>Frustrations</h3>
                <ul>
                  <li>Not much is going on in a small town, which makes it difficult to pursue an artistic career</li>
                  <li>Lack of time doesn't allow her to go to exhibitions and practice her passion</li>
                  <li>Small number of opportunities to display her artwork and attract buyers</li>
                </ul>
                <h3>Goals</h3>
                <ul>
                  <li>Staying up to date with exhibitions at the art gallery and gallery openings</li>
                  <li>Being able to present her own art at the art gallery</li>
                  <li>Visiting more exhibitions for inspiration and socializing</li>
                </ul>
                <p>
                  <span>INTROVERT</span>
                  <span>CREATIVE</span>
                  <span>PASSIONATE</span>
                  <span>KIND</span>
                  <span>ART LOVER</span>
                  <span>ORIGINAL</span>
                  <span>ENTHUSIAST</span>
                </p>
              </div>
            </div>

            <h2>User journey map</h2>
            <p className="max_width_720px">
              Mapping Mia's user journey revealed how helpful it would be to have access to a
              dedicated Art Corner gallery app. Mia's goal is visiting exhibitions of her own
              interest in order to gain inspiration for her projects. She needs to have enough
              information about the upcoming exhibitions to decide which ones to visit.
            </p>

            <img src={actions} className="mh85" alt="Actions" />
          </section>

          <section>
            <h1>Ideate</h1>

            <h2>Information architecture</h2>
            <p className="max_width_720px">
              After the define process, I started with ideation. First, I created the information
              architecture for the Art Corner gallery app. Effective information architecture
              is key to creating a seamless user journey, therefore it should be organized in
              a way to best meet the app's users' needs.
            </p>

            <img src={ideate_01} className="mh85" alt="Ideate" />
            <div className="resp_01 img_mw75">
              <img src={ideate_02} alt="Profile" />
              <img src={ideate_03} alt="Cart" />
            </div>

            <h2>Moodboard</h2>
            <p className="max_width_720px">
              My inspirations were minimalist design, light shades and bright galleries.
              I used the minimum amount of colors to emphasize the images, dyeing with
              pastel colors in order to achieve a soft and pale looking design.
            </p>
            <img src={moodboard} className="mh85" alt="Moodboard" />

            <h2>From paper wireframes to the high-fidelity mockups</h2>
            <p className="max_width_720px">
              For my low-fidelity design I used paper and pen, taking the time to
              draft iterations of each screen of the app. This ensured that the
              elements that made it to digital wireframes would be well-suited to
              address the users pain points. For the home screen I chose to create an
              overview of exhibitions. From the bottom navigation, the users are
              able to quickly access the virtual tours, artworks and their tickets.
            </p>

            <p className="margin_top_regular">Main pages and virtual tours (low-fidelity)</p>
            <img src={sketch_01} className="mh75" alt="Sketch 01" />
            <img src={sketch_02} className="mh75" alt="Sketch 02" />

            <h2>Main pages and virtual tours (high-fidelity)</h2>
            <div className="resp_01">
              <img className="mobile_cutout art_gallery_wireframe_border" src={wireframe_01} alt="Wireframe - exhibitions" />
              <img className="mobile_cutout art_gallery_wireframe_border" src={wireframe_02} alt="Wireframe - virtual tours" />
              <img className="mobile_cutout art_gallery_wireframe_border" src={wireframe_03} alt="Wireframe - artworks" />
              <img className="mobile_cutout art_gallery_wireframe_border" src={wireframe_04} alt="Wireframe - tickets" />
            </div>
            <img src={wireframe_sequence} className="mobile_cutout" alt="Wireframe sequence" />

            <h2>Minimalist design and user-friendly interfaces</h2>
            <div id="art_gallery_fade" className="resp_01">
              <div className="art_gallery_padding">
                <img src={interface_01_foreground} className="mobile_cutout" alt="Virtual tours" />
                <img src={interface_01_background} className="mobile_cutout" alt="Exhibitions" />
              </div>
              <div className="art_gallery_padding">
                <img src={interface_02_foreground} className="mobile_cutout" alt="Tickets" />
                <img src={interface_02_background} className="mobile_cutout" alt="Artworks" />
              </div>
            </div>

            <h2>Virtual tours</h2>
            <p className="max_width_720px">
              One of the most important goals for this application was to enable users to
              visit the galleries virtually. I put a lot of effort into creating the best
              possible experience using this feature. In addition to the visual experiences
              of the location and artworks, the user is provided with all the information
              about the presented pieces in various languages, both in textual and audio
              descriptions. All the functions can be adjusted at any time during the virtual tour.
            </p>
            <video autoPlay loop muted className="art_gallery_padding mobile_cutout">
              <source src={virtual_tour_video} type="video/mp4" />
            </video>
            <div id="art_gallery_virtual_tours_sunset" className="resp_01 art_gallery_padding">
              <img src={virtual_tours_sunset_01} className="mobile_cutout" alt="Sunset 01" />
              <img src={virtual_tours_sunset_02} className="mobile_cutout" alt="Sunset 02" />
              <img src={virtual_tours_sunset_03} className="mobile_cutout" alt="Sunset 03" />
            </div>

            <h2>Artworks</h2>
            <p className="max_width_720px">
              Users can easily save, share and purchase artwork on any device
              using the Art Corner gallery. Even though the app provides all the
              necessary information, users can still contact the gallery via
              live chat anytime. Every purchased piece can be tracked during
              the entire process - from shipping to delivery.
            </p>
            <video autoPlay loop muted className="art_gallery_padding mobile_cutout">
              <source src={artworks_video} type="video/mp4" />
            </video>
            <div id="art_gallery_artworks" className="resp_01">
              <img src={artworks_01} alt="Soul of a sad girl" />
              <div>
                <img src={artworks_02} className="art_gallery_small_img" alt="Watching sunset with you" />
                <img src={artworks_03} className="art_gallery_small_img" alt="Sleeping beauty" />
              </div>
              <div>
                <img src={artworks_04} className="art_gallery_small_img" alt="Color explosion" />
                <img src={artworks_05} className="art_gallery_small_img" alt="Little girl" />
                <img className="border_radius_medium mh75" src={artworks_06} alt="Sleeping beauty (phone)" />
              </div>
            </div>

            <h2>Ticket booking</h2>
            <p className="max_width_720px">
              Users can easily book tickets for exhibitions via the gallery app,
              and they will be automatically saved in the ticket section.
            </p>
            <video autoPlay loop muted className="art_gallery_padding mobile_cutout">
              <source src={buy_ticket_video} type="video/mp4" />
            </video>

            <h2>Fast check-in at the exhibition</h2>
            <p className="max_width_720px">
              Users can easily access their tickets in the 'Tickets' section of the app.
              This is possible anytime, even offline. They don't need paper tickets to
              visit exhibitions, because the digital tickets are on hand and ready to be
              scanned at the entrance of the gallery. They can also skip "meeting points"
              by sharing tickets with their entourage.
            </p>
            <div className="resp_01 w2k img_mw75">
              <img src={tickets_01} alt="Tickets" />
              <img src={tickets_02} alt="Tickets" />
              <img src={tickets_03} alt="Tickets" />
            </div>

            <h2>More screens</h2>
            <SlideShow
              id="art_gallery_more_screens_slide_show"
              imageArray={moreScreensArray}
              imageClassesArray={["art_gallery_padding", "mobile_cutout"]}
            />
          </section>

          <section id="art_gallery_takeaways">
            <h1>Takeaways</h1>
            <p className="max_width_720px">
              I have learned that it is very important to concentrate on the MVP first.
              The process of creating a product should start with the design of fundamental
              parts, and continue with iterations to expand and enhance it, until
              all the features, even those not initially planned, are complete.
            </p>
            <div className="resp_01">
              <div>
                <h2>Future plan 1</h2>
                <p>
                  Increasing interactivity during the virtual tour. Using augmented reality
                  and machine learning to enable users to place virtual images from the Art
                  Corner gallery on their walls, canvases, papers etc.
                </p>
              </div>
              <div>
                <h2>Future plan 2</h2>
                <p>
                  Implementing an artwork scanner to be able to get more information about
                  the pieces during the gallery visit. The information will be provided in
                  audio or textual form with several languages to choose from.
                </p>
              </div>
            </div>
          </section>
          <ProjectsCarousel projectIndex={projectIndex} />
        </section>
      </main>
      <Footer />
    </>
  );
}

export default ArtGallery;
