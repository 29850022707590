import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import Home from "./home/Home";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import ProjectsData from "./home/ProjectsData";
import ArtGallery from "./projects/art_gallery/ArtGallery";
import NoteX from "./projects/notex/NoteX";
import PetNanny from "./projects/pet_nanny/PetNanny";
import Mango from "./projects/mango/Mango";
import Captured from "./projects/captured/Captured";

const artGalleryProjectIndex = 0;
const notexProjectIndex = 1;
const petNannyProjectIndex = 2;
const mangoProjectIndex = 3;
const capturedProjectIndex = 4;

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path={`${ProjectsData[artGalleryProjectIndex].path}`}
          element={<ArtGallery projectIndex={artGalleryProjectIndex} />}
        />
        <Route
          path={`${ProjectsData[notexProjectIndex].path}`}
          element={<NoteX projectIndex={notexProjectIndex} />}
        />
        <Route
          path={`${ProjectsData[petNannyProjectIndex].path}`}
          element={<PetNanny projectIndex={petNannyProjectIndex} />}
        />
        <Route
          path={`${ProjectsData[mangoProjectIndex].path}`}
          element={<Mango projectIndex={mangoProjectIndex} />}
        />
        <Route
          path={`${ProjectsData[capturedProjectIndex].path}`}
          element={<Captured projectIndex={capturedProjectIndex} />}
        />
      </Routes>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
