import React from "react";
import Header from "../../Header";
import GeneralInformation from "../GeneralInformation";
import style_guide from "../../images/04_mango/style_guide.webp";
import ColorsAndTypography from "./ColorsAndTypography";
import mobile_original from "../../images/04_mango/mobile_original.webp";
import mobile_redesign from "../../images/04_mango/mobile_redesign.webp";
import product_card from "../../images/04_mango/product_card.webp";
import menu_original from "../../images/04_mango/menu_original.webp";
import menu_redesign from "../../images/04_mango/menu_redesign.webp";
import filter_original_design from "../../images/04_mango/filter_original_design.webp";
import filter_redesign_desktop from "../../images/04_mango/filter_redesign_desktop.webp";
import filter_redesign_tablet from "../../images/04_mango/filter_redesign_tablet.webp";
import filter_redesign_mobile from "../../images/04_mango/filter_redesign_mobile.webp";
import checkout_original_design_01 from "../../images/04_mango/checkout_original_design_01.webp";
import checkout_original_design_02 from "../../images/04_mango/checkout_original_design_02.webp";
import checkout_original_design_03 from "../../images/04_mango/checkout_original_design_03.webp";
import checkout_original_design_04 from "../../images/04_mango/checkout_original_design_04.webp";
import checkout_redesign_01_login from "../../images/04_mango/checkout_redesign_01_login.webp";
import checkout_redesign_02_delivery from "../../images/04_mango/checkout_redesign_02_delivery.webp";
import checkout_redesign_03_payment from "../../images/04_mango/checkout_redesign_03_payment.webp";
import checkout_redesign_04_confirmation from "../../images/04_mango/checkout_redesign_04_confirmation.webp";
import checkout_redesign_05_summary from "../../images/04_mango/checkout_redesign_05_summary.webp";
import checkout_redesign_mobile_01 from "../../images/04_mango/checkout_redesign_mobile_01.webp";
import checkout_redesign_mobile_02 from "../../images/04_mango/checkout_redesign_mobile_02.webp";
import checkout_redesign_mobile_03 from "../../images/04_mango/checkout_redesign_mobile_03.webp";
import checkout_redesign_mobile_04 from "../../images/04_mango/checkout_redesign_mobile_04.webp";
import checkout_redesign_mobile_05 from "../../images/04_mango/checkout_redesign_mobile_05.webp";
import checkout_redesign_mobile_06 from "../../images/04_mango/checkout_redesign_mobile_06.webp";
import mango_video from "../../images/04_mango/mango.mp4";
import homepage_desktop from "../../images/04_mango/homepage_desktop.webp";
import homepage_mobile from "../../images/04_mango/homepage_mobile.webp";
import product_overview_desktop from "../../images/04_mango/product_overview_desktop.webp";
import product_overview_tablet from "../../images/04_mango/product_overview_tablet.webp";
import product_overview_mobile from "../../images/04_mango/product_overview_mobile.webp";
import ProjectsCarousel from "../ProjectsCarousel";
import Footer from "../../Footer";

function Mango({ projectIndex })
{
  return (
    <>
      <Header />
      <main>
        <GeneralInformation projectIndex={projectIndex} />

        <section>
          <h1>Project overview</h1>

          <h2>About the project</h2>
          <p>
            Mango is a worlwide popular clothing design and manufacturing company from Spain.
            This brand was created with the goal of providing reasonably priced clothing
            worldwide to the young and urban feminine market while still following the
            latest fashionable trends. The decision to redesign their eCommerce website was
            solely based on my personal experience as a long-term customer.
          </p>

          <p id="mango_note">
            Note: As a result, this case study is not exhaustive, and I am certainly
            not implying that Mango abandon their current design and adopt mine.
          </p>

          <h2>Responsibilities</h2>
          <p>
            Data analysis, paper and digital wire framing,
            high-fidelity prototyping, and iterating on designs.
          </p>
        </section>

        <section>
          <h1>Problem statement</h1>

          <h2>Problem</h2>
          <p>
            After reading customer reviews about the company, I identified the main
            problems that users face when using the website. Many of them encounter
            difficulties in navigating the website and the checkout process - two key
            features of the online shopping experience.
          </p>

          <h2>Solution</h2>
          <p>
            Focusing on the navigation, filter options, product display layout and checkout
            experience to resolve the most important problems the customers are facing.
            My goal was to provide a smooth user journey, and to add a more elegant, modern,
            minimalistic look to the website which fits the brand's identity.
          </p>

          <p id="mango_quote">
            <q>We inspire the world by sharing our passion for Mediterranean style and culture</q> - Mango Fashion Company
          </p>
        </section>

        <section>
          <h1>Style guide</h1>

          <img className="thin_light_border" src={style_guide} alt="Style guide" />

          <ColorsAndTypography />

        </section>
      </main>

      <section id="mango_paint_points">
        <main>
          <h1>Pain points</h1>

          <section>
            <h2>Responsiveness</h2>
            <p className="max_width_720px">
              During my research, I encountered some responsiveness issues across devices.
              Based on numerous studies and mobile eCommerce statistics, more than half of
              online shopping traffic goes through mobile devices. That's why it is extremely
              important to make sure the website is primed for mobile viewing.
            </p>

            <div id="mango_responsiveness" className="resp_01">
              <div>
                <img className="thin_light_border mh75" src={mobile_original} alt="Original mobile" />
                <div>
                  <h2>Original mobile</h2>
                  <p>
                    The product name is overlapping the save button. I changed the textual
                    color description to a visual element so that users can immediately
                    identify the available colors of the desired item.
                  </p>
                </div>
              </div>
              <div>
                <img className="mh75" src={mobile_redesign} alt="Redesign mobile" />
                <div>
                  <h2>Redesign mobile</h2>
                  <p>
                    I moved the save button to the top right corner of the product
                    image to avoid overlapping with the text, and organized the
                    product information into a clean layout.
                  </p>
                </div>
              </div>
            </div>
          </section>

          <section>
            <h2>Product card</h2>
            <img className="thin_light_border mh75" src={product_card} alt="Product card" />
          </section>

          <section>
            <h2>Navigation and dropdown menu</h2>
            <p className="max_width_720px">
              The original Mango website uses auto-switch language and currency converter
              modules, which set the default language and currency according to country. This
              is a very good practice, and improves the user experience. However, changing the
              language or currency has to be quick and easy. This is why I put this option
              to the top navigation bar.
            </p>

            <h2>Original design</h2>
            <img className="thin_light_border" src={menu_original} alt="Menu - original design" />

            <h2>Redesign</h2>
            <p className="max_width_720px">
              I reorganized the categories in the drop-down menu, so that the users have
              instant insight into the list of items that belong to a particular category.
              When a user hovers over a title, an image appears as a visual display
              on the right side of the dropdown menu. And, as previously mentioned, the
              language and currency settings are located in the top-right corner.
            </p>
            <img src={menu_redesign} alt="Menu - redesign" />
          </section>

          <section>
            <h2>Filter options</h2>
            <p className="max_width_720px">
              The original Mango website uses too much negative space to display the filter
              options. This makes discovering the CTA button difficult. Also, the color filter
              only provides a textual description, which benefits users with visual impairment,
              but many people can't identify colors solely by their names. Surprisingly,
              not every section provides a "sort by" option, which makes the browsing less
              user-friendly.
            </p>

            <h2>Original design</h2>
            <img className="thin_light_border" src={filter_original_design} alt="Filter original design" />

            <h2>Redesign</h2>
            <p className="max_width_720px">
              I organized the filter section into categories to make the options instantly
              recognizable, with no additional clicking. I added visual elements to the
              color filter, next to the textual description. To enhance the browsing
              experience, I created a separate section for sorting products.
            </p>
            <img src={filter_redesign_desktop} alt="Filter redesign desktop" />
            <div id="mango_filter_redesign" className="resp_01">
              <img className="thin_light_border mh75" src={filter_redesign_tablet} alt="Filter redesign tablet" />
              <img className="thin_light_border mh75" src={filter_redesign_mobile} alt="Filter redesign mobile" />
            </div>
          </section>

          <section>
            <h2>Checkout</h2>
            <p className="max_width_720px">
              The checkout process is always sensitive for the customer. The entire user experience
              can be ruined if something goes wrong here. In the Mango e-commerce website's customer
              reviews I noticed that users are often dissatisfied with this section. Clearly, it needs
              improvement. The first thing I noticed is that there is no option to login or register
              with a social media account. This forces users to fill out additional fields at checkout.
              Second, there are language inconsistencies in the input fields. Some of them are not
              available in the selected language. Finally, the delivery and payment page is overwhelming
              due to lack of hierarchy and amount of information.
            </p>

            <h2>Original design</h2>
            <img className="thin_light_border" src={checkout_original_design_01} alt="Checkout original design" />
            <img className="thin_light_border" src={checkout_original_design_02} alt="Checkout original design" />
            <img className="thin_light_border" src={checkout_original_design_03} alt="Checkout original design" />
            <img className="thin_light_border" src={checkout_original_design_04} alt="Checkout original design" />
          </section>
        </main>

        <section id="mango_checkout_redesign">
          <main>
            <div>
              <h2>Redesign</h2>

              <p className="max_width_720px">
                To make the checkout section user-friendly I organized it into different pages
                and added the option to log in or register with various social media accounts.
                As an additional user experience improvement I added the gift option. Customers
                can now send their orders to someone wrapped up as gifts.
              </p>

              <img src={checkout_redesign_01_login} alt="Login redesign" />
              <img src={checkout_redesign_02_delivery} alt="Delivery redesign" />
              <img src={checkout_redesign_03_payment} alt="Payment redesign" />
              <img src={checkout_redesign_04_confirmation} alt="Confirmation redesign" />
              <img src={checkout_redesign_05_summary} alt="Summary redesign" />

              <div className="resp_01">
                <img src={checkout_redesign_mobile_01} alt="Shopping cart (mobile)" />
                <img src={checkout_redesign_mobile_02} alt="Login or register (mobile)" />
                <img src={checkout_redesign_mobile_03} alt="User data and delivery address (mobile)" />
              </div>
              <div className="resp_01">
                <img src={checkout_redesign_mobile_04} alt="Payment (mobile)" />
                <img src={checkout_redesign_mobile_05} alt="Confirmation (mobile)" />
                <img src={checkout_redesign_mobile_06} alt="Overview (mobile)" />
              </div>
            </div>
          </main>
        </section>
      </section>

      <main>
        <section>
          <h1>Main pages and screens</h1>
          <p className="max_width_720px">
            I've created a clean and simple design to lower the risk of overwhelming users.
            It represents the brand's identity and creates a "high-fashion" look.
          </p>

          <h2>Home page</h2>
          <video className="thin_light_border mh85" autoPlay loop muted>
            <source src={mango_video} type="video/mp4" />
          </video>
          <div className="mango_desktop_mobile resp_01">
            <img src={homepage_desktop} alt="Homepage desktop" />
            <img src={homepage_mobile} alt="Homepage mobile" />
          </div>

          <h2>Product overview page</h2>
          <img src={product_overview_desktop} alt="Product overview desktop" />
          <div className="resp_01">
            <img src={product_overview_tablet} alt="Product overview tablet" />
            <img src={product_overview_mobile} alt="Product overview mobile" />
          </div>
        </section>

        <section className="disclaimer">
          <p>All images were taken from the official website and belong to their owners.</p>
          <p>This is a non-commercial project.</p>
        </section>

        <ProjectsCarousel projectIndex={projectIndex} />
      </main>
      <Footer />
    </>
  );
}

export default Mango;
