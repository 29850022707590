import React, { useEffect } from "react";

function Footer()
{
  const scrollToTopSmooth = () =>
  {
    window.scroll({ top: 0, behavior: 'smooth' });
  };

  useEffect(() =>
  {
    const handleScrollToTopButtonOnScroll = event =>
    {
      const viewHeight = window.screen.availHeight;
      const currentScrollY = window.scrollY;
      const shouldHide = currentScrollY < viewHeight;

      const scrollToTopButton = document.getElementById("footer_circle_jump_button");

      scrollToTopButton.style.visibility = shouldHide ? "hidden" : "visible";
    };

    window.addEventListener('scroll', handleScrollToTopButtonOnScroll);

    return () =>
    {
      window.removeEventListener('scroll', handleScrollToTopButtonOnScroll);
    };
  }, []);

  return (
    <footer>
      <h2>Get in touch</h2>
      <a id="footer_email" href="mailto:kandrea.andrea@gmail.com">
        <h3>kandrea.andrea@gmail.com</h3>
      </a>
      <button onClick={scrollToTopSmooth} id="footer_circle_jump_button" className="circle_jump_button">
        <svg
          width="48"
          height="26"
          fill="#FFF"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M2.82829 24.7695L22.5863 5.01153C22.9665 4.64655 23.4732
            4.44275 24.0003 4.44275C24.5274 4.44275 25.034 4.64655 25.4143
            5.01153L45.1603 24.7595L47.9883 21.9315L28.2423 2.18353C27.0986
            1.09349 25.5792 0.485432 23.9993 0.485432C22.4193 0.485432 20.9
            1.09349 19.7563 2.18353L0.000285855 21.9415L2.82829 24.7695Z"
          />
        </svg>
      </button>
      <p>Copyright © All rights reserved</p>
    </footer>
  );
}

export default Footer;
