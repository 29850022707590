import React from "react";
import Factor from "./Factor";
import factorsData from "./FactorsData";

function Factors()
{
  return (
    <div id="pet_nanny_factors" className="w2k resp_01">
      {factorsData.map(
        (factor) => <Factor
          key={factor.id}
          imageObject={factor.imageObject}
          titleText={factor.titleText}
          listItemsText={factor.listItemsText}
        />)}
    </div>
  );
}

export default Factors;
