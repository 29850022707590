import React from "react";
import DesignStep from "./DesignStep";

import discover from "../../images/03_pet_nanny/design_process_01_discover.webp";
import define from "../../images/03_pet_nanny/design_process_02_define.webp";
import ideate from "../../images/03_pet_nanny/design_process_03_ideate.webp";
import prototype from "../../images/03_pet_nanny/design_process_04_prototype.webp";
import test from "../../images/03_pet_nanny/design_process_05_test.webp";

function DesignSteps()
{
  return (
    <div id="pet_nanny_design_steps" className="resp_01">
      <DesignStep imageSrc={discover} titleText="Discover" />
      <DesignStep imageSrc={define} titleText="Define" />
      <DesignStep imageSrc={ideate} titleText="Ideate" />
      <DesignStep imageSrc={prototype} titleText="Prototype" />
      <DesignStep imageSrc={test} titleText="Test" />
    </div>
  );
}

export default DesignSteps;
