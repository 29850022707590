import React from "react";
import Header from "../../Header";
import GeneralInformation from "../GeneralInformation";
import ProjectOverview from "../ProjectOverview";
import DesignSteps from "./DesignSteps";
import Factors from "./Factors";
import competitors from "../../images/03_pet_nanny/competitors.webp";
import nicole from "../../images/03_pet_nanny/nicole.webp";
import user_journey from "../../images/03_pet_nanny/user_journey.webp";
import information_architecture from "../../images/03_pet_nanny/information_architecture.png";
import style_guide from "../../images/03_pet_nanny/style_guide.webp";
import pet_sitter_01 from "../../images/03_pet_nanny/pet_sitter_01.mp4";
import pet_sitter_02 from "../../images/03_pet_nanny/pet_sitter_02.mp4";
import safe_pet_01 from "../../images/03_pet_nanny/safe_pet_01.webp";
import safe_pet_02 from "../../images/03_pet_nanny/safe_pet_02.webp";
import safe_pet_03 from "../../images/03_pet_nanny/safe_pet_03.webp";
import safe_pet_04 from "../../images/03_pet_nanny/safe_pet_04.webp";
import booking_video from "../../images/03_pet_nanny/booking.mp4";
import info_01 from "../../images/03_pet_nanny/info_01.webp";
import info_02 from "../../images/03_pet_nanny/info_02.webp";
import request_01 from "../../images/03_pet_nanny/request_01.webp";
import request_02 from "../../images/03_pet_nanny/request_02.webp";
import request_03 from "../../images/03_pet_nanny/request_03.webp";
import request_04 from "../../images/03_pet_nanny/request_04.webp";
import request_05 from "../../images/03_pet_nanny/request_05.webp";
import nearby_sitters_01 from "../../images/03_pet_nanny/nearby_sitters_01.webp";
import nearby_sitters_02 from "../../images/03_pet_nanny/nearby_sitters_02.webp";
import SlideShow from "../SlideShow";
import onboarding_01 from "../../images/03_pet_nanny/onboarding_01.webp";
import onboarding_02 from "../../images/03_pet_nanny/onboarding_02.webp";
import onboarding_03 from "../../images/03_pet_nanny/onboarding_03.webp";
import onboarding_04 from "../../images/03_pet_nanny/onboarding_04.webp";
import onboarding_05 from "../../images/03_pet_nanny/onboarding_05.webp";
import onboarding_06 from "../../images/03_pet_nanny/onboarding_06.webp";
import checkout_01 from "../../images/03_pet_nanny/checkout_01.webp";
import checkout_02 from "../../images/03_pet_nanny/checkout_02.webp";
import checkout_03 from "../../images/03_pet_nanny/checkout_03.webp";
import checkout_04 from "../../images/03_pet_nanny/checkout_04.webp";
import checkout_05 from "../../images/03_pet_nanny/checkout_05.webp";
import credit_card_01 from "../../images/03_pet_nanny/credit_card_01.webp";
import credit_card_02 from "../../images/03_pet_nanny/credit_card_02.webp";
import credit_card_03 from "../../images/03_pet_nanny/credit_card_03.webp";
import wireframe_01 from "../../images/03_pet_nanny/wireframe_01.webp";
import wireframe_02 from "../../images/03_pet_nanny/wireframe_02.webp";
import wireframe_03 from "../../images/03_pet_nanny/wireframe_03.webp";
import wireframe_04 from "../../images/03_pet_nanny/wireframe_04.webp";
import wireframe_05 from "../../images/03_pet_nanny/wireframe_05.webp";
import wireframe_06 from "../../images/03_pet_nanny/wireframe_06.webp";
import interface_01 from "../../images/03_pet_nanny/interface_01.webp";
import interface_02 from "../../images/03_pet_nanny/interface_02.webp";
import interface_03 from "../../images/03_pet_nanny/interface_03.webp";
import ProjectsCarousel from "../ProjectsCarousel";
import Footer from "../../Footer";

function PetNanny({ projectIndex })
{
  const aboutText = "The PetNanny app connects pet owners with verified pet sitters in " +
    "their vicinity. PetNanny provides the owners the means to easily find assistance " +
    "for taking care of their buddies. Numerous services are available, such as: " +
    "home sitting, walking, day care, grooming and boarding.";

  const responsibilitiesText = "Conducting interviews, paper and digital wire framing, " +
    "low and high-fidelity prototyping, conducting usability studies, accounting " +
    "for accessibility, and iterating on designs.";

  const onboardingImageArray = [
    onboarding_01,
    onboarding_02,
    onboarding_03,
    onboarding_04,
    onboarding_05,
    onboarding_06,
  ];

  return (
    <>
      <Header />
      <main>
        <GeneralInformation projectIndex={projectIndex} />

        <ProjectOverview aboutText={aboutText} responsibilitiesText={responsibilitiesText} />

        <section>
          <h1>Problem statement</h1>

          <h2>Problem</h2>
          <p>
            Pet owners are facing difficulties in finding someone who can
            take care of their beloved pets while they are away or busy.
          </p>

          <h2>Solution</h2>
          <p>
            Designing the PetNanny app, which easily connects pet owners
            with verified, reliable, and loving pet sitters.
          </p>
        </section>

        <section>
          <h1>Design process</h1>

          <DesignSteps />
        </section>

        <section>
          <h1>Discover</h1>

          <h2>Interviews</h2>
          <p className="max_width_720px">
            I started my research with stakeholder interviews. I conducted interviews
            with potential users to understand their needs. This helped me create a
            user profile I'm designing for. The primary user group identified were
            working pet owners who travel often. The participants were 3 ladies and
            2 gentlemen, aged between 18 and 45. 4 of them have dogs and 1 a cat. They
            all mentioned that they require professional assistance with their pets
            when they are away or busy.
          </p>

          <div id="pet_nanny_question">
            <p>I asked questions like:</p>
            <p className="max_width_720px">
              How do you manage to find the right pet sitter? How often do you use pet
              sitting services? Which problems do you face while looking for a sitter?
            </p>
          </div>

          <p className="max_width_720px">
            This user group confirmed the initial assumptions about the PetNanny users,
            that accessibility is a capital issue. But the research also revealed that
            is not the only obstacle preventing users from finding the right pet sitter
            to take care of their pets. Other major factors are financial, psychological
            (separation anxiety, insecurity, mistrust etc.) and time.
            The main pain points I identified:
          </p>

          <Factors />

          <h2>Competitor analysis</h2>
          <p className="max_width_720px">
            The next step was identifying the direct and indirect competitors of the
            PetNanny app. The direct competitors are 'Pawshake' and 'PetBacker', and
            I see 'Cat in a Flat' as an indirect competitor.
          </p>
          <p className="max_width_720px">
            I was able to identify the useful functionalities they offer, and also
            their oversights by reading many reviews, using the apps myself, and
            talking to other users. I compiled all the information I gathered to
            evaluate their strengths and weaknesses.
          </p>

          <h2>Some insights from the competitor analysis:</h2>
          <div id="pet_nanny_competitors" className="resp_01">
            <div>
              <img src={competitors} alt="Competitors" />
            </div>
            <div>
              <ul>
                <li>Finding a way to book a pet sitter for the same service on a weekly basis</li>
                <li>Adding a pet tracker for safety</li>
                <li>Providing a great communication system to the users before, during and after the sitting</li>
                <li>Highlighting reviews, because they are very important for the user</li>
                <li>Reminding the user about the upcoming service (to try to prevent no-shows)</li>
                <li>Finding a matching replacement in case of last minute cancellations</li>
                <li>Creating a profile for the pet for future bookings</li>
              </ul>
            </div>
          </div>
        </section>

        <section>
          <h1>Define</h1>

          <h2>Persona</h2>
          <p className="max_width_720px">
            Based on the information I gathered throughout my research, I was able to
            create a persona who represents the target audience of the PetNanny app.
            My persona, Nicole, is a stewardess, and is often away from home.
            Unfortunately, she can't take her dog, Buddy, with her, so she needs a
            pet sitter on regular basis. Nicole's neighbor has been helping out, but
            he is moving away next month, so she has to find a reliable person to
            take care of her Buddy.
          </p>

          <div id="nicole_persona">
            <div className="resp_01">
              <div>
                <hr />
                <h3>Persona</h3>
                <h2>Nicole, 30</h2>
                <p><span>Hometown:</span> Vienna, Austria</p>
                <p><span>Family:</span> Single, lives alone in an apartment</p>
                <p><span>Occupation:</span> Flight attendant</p>

                <q>
                  I feel so bad for leaving Buddy with a stranger!
                  I would love to spend more time with him. I miss him!
                </q>

                <h2>Frustrations:</h2>
                <ul>
                  <li>Not spending enough time with Buddy</li>
                  <li>Leaving Buddy alone with a stranger</li>
                  <li>Pet sitters cancelling at the last minute</li>
                </ul>
                <h2>Goals</h2>
                <ul>
                  <li>Spend more time with Buddy</li>
                  <li>Find a reliable and trustworthy pet sitter to take care of Buddy on a regular basis</li>
                </ul>
              </div>
              <img className="border_radius_medium" src={nicole} alt="Nicole" />
            </div>
            <hr />
          </div>

          <h2>User journey map</h2>
          <p className="max_width_720px">
            Mapping Nicole's user journey revealed how helpful it would be for users to have access
            to a dedicated PetNanny app. Nicole's goal is to find a reliable and trustworthy pet
            sitter, who can take care of Buddy regularly while she is away.
          </p>
          <img src={user_journey} className="mh85" alt="User journey" />
        </section>

        <section>
          <h1>Ideate</h1>

          <h2>Information architecture</h2>
          <p className="max_width_720px">
            After the define process, I started with ideation. First, I created the PetNanny
            app's information architecture. Effective information architecture is key for
            creating a seamless user journey, therefore, it should be organized in a way that
            best meets the PetNanny app's user's needs.
          </p>
          <img src={information_architecture} alt="Information architecture" />

          <h2>From paper wireframes to the high-fidelity mockups</h2>
          <p className="max_width_720px">
            Creating the first pages of the app was a lot of fun! I used paper and pen to
            create low-fidelity wireframes. Taking the time to draft iterations of each
            screen of the app on paper ensured that the elements that made it to digital
            wireframes would be well-suited to address the user pain points. For the home
            screen, I prioritized quick access to the main functionalities of the app, such
            as searching for a pet sitter, pet tracking option, and messaging. I made lot of
            changes on the design during the whole process, bearing in mind the user's needs.
          </p>
          <p className="margin_top_regular max_width_720px">
            Booking a pet sitter (low-fidelity wireframes)
          </p>
          <div id="pet_nanny_wireframes" className="resp_01 w2k">
            <img className="border_radius_medium" src={wireframe_01} alt="Wireframe 01" />
            <img className="border_radius_medium" src={wireframe_02} alt="Wireframe 02" />
            <img className="border_radius_medium" src={wireframe_03} alt="Wireframe 03" />
            <img className="border_radius_medium" src={wireframe_04} alt="Wireframe 04" />
            <img className="border_radius_medium" src={wireframe_05} alt="Wireframe 05" />
            <img className="border_radius_medium" src={wireframe_06} alt="Wireframe 06" />
          </div>

          <h2>Style guide</h2>
          <img className="thin_light_border mh85" src={style_guide} alt="Style guide" />

          <h2>Onboarding</h2>
          <SlideShow
            id="pet_nanny_onboarding_slide_show"
            imageArray={onboardingImageArray}
            imageClassesArray={["border_radius_medium", "pet_nanny_border", "mobile_cutout"]}
          />

          <h2>Intuitive navigation and user-friendly interfaces</h2>
          <div className="w2k pet_nanny_padding resp_01">
            <img className="border_radius_medium mobile_cutout" src={interface_01} alt="Interface 01" />
            <img className="border_radius_medium mobile_cutout" src={interface_02} alt="Interface 02" />
            <img className="border_radius_medium mobile_cutout" src={interface_03} alt="Interface 03" />
          </div>

          <h2>Finding a pet sitter has never been easier</h2>
          <div className="resp_01 pet_nanny_padding">
            <video className="border_radius_medium mobile_cutout" autoPlay loop muted>
              <source src={pet_sitter_01} type="video/mp4" />
            </video>
            <video className="border_radius_medium mobile_cutout" autoPlay loop muted>
              <source src={pet_sitter_02} type="video/mp4" />
            </video>
          </div>

          <h2>The pets are in safe hands</h2>
          <p className="max_width_720px">
            The user wants to make sure that his pet is in good hands and safe during
            sitting. The application allows pet owners to track the movements of their
            pets, even without directly contacting the pet sitter. A GPS tracking
            system is available inside the app, so the users can always check the
            whereabouts of their pets. Additionally, the pet owner can communicate with
            the sitter during the sitting, by using the video chat or messaging feature
            of the app.
          </p>
          <div id="pet_nanny_safe_hands" className="w2k pet_nanny_padding resp_01">
            <img className="border_radius_medium mobile_cutout" src={safe_pet_01} alt="Find the pet sitter" />
            <img className="border_radius_medium mobile_cutout" src={safe_pet_02} alt="Pet location" />
            <img className="border_radius_medium mobile_cutout" src={safe_pet_03} alt="Contact pet sitter" />
            <img className="border_radius_medium mobile_cutout" src={safe_pet_04} alt="Conversation" />
          </div>

          <h2>Quick and easy booking</h2>
          <p className="max_width_720px">
            Users can create profiles for their pets providing all the important information
            about them. These profiles can be created and edited at any time. When booking,
            the pet owner only needs to select the pet for the service, and its profile
            will be automatically forwarded to the pet sitter. This removes the need to
            retype the information for every booking and saves a lot of time.
          </p>
          <div id="pet_nanny_quick_and_easy_booking">
            <div className="resp_01">
              <img src={info_01} className="border_radius_medium pet_nanny_border" alt="Information about you" />
              <img src={info_02} className="border_radius_medium pet_nanny_border" alt="Pet's profile" />
            </div>
            <div className="resp_01 pet_nanny_notification_margin">
              <div>
                <img src={request_01} className="border_radius_medium pet_nanny_border" alt="No profile" />
              </div>
              <div>
                <img src={request_02} className="border_radius_medium pet_nanny_border" alt="Create a request" />
                <img src={request_03} className="border_radius_medium pet_nanny_border" alt="Processing request" />
              </div>
              <div>
                <img src={request_04} className="border_radius_medium pet_nanny_border" alt="Request overview" />
                <img src={request_05} className="border_radius_medium pet_nanny_border" alt="Request submitted" />
              </div>
            </div>
          </div>

          <h2>Recurring booking</h2>
          <p className="max_width_720px">
            Users often need particular pet services on a regular basis. Their pet may need
            a walk every Saturday at 7pm or maybe a home sitting every Sunday. The PetNanny
            app enables users to book recurring services quick and easy. These bookings can
            modified at any time.
          </p>
          <div id="pet_nanny_booking_video" className="pet_nanny_padding">
            <video className="border_radius_medium mobile_cutout" autoPlay loop muted>
              <source src={booking_video} type="video/mp4" />
            </video>
          </div>
        </section>

        <section>
          <h1>Prototype and usability testing</h1>

          <h2>Usability testing of the main flows</h2>
          <p className="max_width_720px">
            Using the completed set of digital mockups, I created a high-fidelity prototype.
            I connected two user flows in order to test them. The primary user flow was finding
            and booking a pet sitter. The secondary user flow was the checkout process.
          </p>

          <h2>User flow 1 - searching for a pet sitter</h2>
          <p className="max_width_720px">
            Users want to see the prices of the pet sitters' services for the date and time
            they specified in their requests, not just their listed prices for all services.
            The search will filter the results, and the users won't waste time viewing
            the pet sitters' profiles, especially the ones they can't afford.
          </p>
          <div id="pet_nanny_usability" className="w2k resp_01">
            <div>
              <img src={nearby_sitters_01} className="border_radius_medium pet_nanny_border mh75" alt="Nearby sitters 01" />
              <div>
                <h2>Before</h2>
                <p>
                  Matching pet sitters list: only services and their price ranges have been listed.
                  The users needed to open the pet sitters' profiles to find out how much they are
                  charging for the requested service (calculating by themselves). No information
                  about the total cost of the service per day/hour before the checkout process.
                </p>
              </div>
            </div>
            <div>
              <img src={nearby_sitters_02} className="border_radius_medium pet_nanny_border mh75" alt="Nearby sitters 02" />
              <div>
                <h2>After</h2>
                <p>
                  Matching pet sitters: the total cost of the requested service is listed. The pet
                  owner will find all the available pet sitters nearby and their total price for
                  the requested service. It greatly simplifies the search for a pet sitter. The user
                  can make changes in his request anytime, by clicking on the request details.
                </p>
              </div>
            </div>
          </div>

          <h2>User flow 2 - checkout</h2>
          <p className="max_width_720px">
            Users found the checkout process really smooth and easy. The option to scan their
            card instead of entering the details manually was very helpful. They liked the fact
            that they were able to see all their transaction made in the app.
          </p>
          <div className="w2k resp_01 img_mw75 pet_nanny_notification_margin">
            <img src={checkout_01} className="border_radius_medium pet_nanny_border resp_equal" alt="Checkout - request accepted" />
            <img src={checkout_02} className="border_radius_medium pet_nanny_border resp_equal" alt="Checkout - request details" />
            <img src={checkout_03} className="border_radius_medium pet_nanny_border resp_equal" alt="Checkout - sitter's profile" />
            <div className="resp_equal">
              <img src={checkout_04} className="border_radius_medium pet_nanny_border" alt="Checkout - payment" />
              <img src={checkout_05} className="border_radius_medium pet_nanny_border" alt="Checkout - thank you" />
            </div>
          </div>

          <h2>Credit card adjustments</h2>
          <div className="resp_01">
            <img src={credit_card_01} className="border_radius_medium pet_nanny_border mobile_cutout" alt="Credit card - payment settings" />
            <img src={credit_card_02} className="border_radius_medium pet_nanny_border mobile_cutout" alt="Credit card - scan card" />
            <img src={credit_card_03} className="border_radius_medium pet_nanny_border mobile_cutout" alt="Credit card - my wallet" />
          </div>
        </section>

        <section>
          <h1>Takeaways</h1>
          <p className="max_width_720px">
            While designing the PetNanny app, I've learned that the first ideas for the app
            are only the beginning of the process. Usability studies and peer feedback
            influenced each iteration of the app's designs. My future plans are to conduct
            another usability study to validate whether the pain points of users have been
            properly assessed, and to create a design of the app for the pet sitter as a user.
          </p>
        </section>

        <ProjectsCarousel projectIndex={projectIndex} />
      </main>
      <Footer />
    </>
  );
}

export default PetNanny;
