import React from "react";
import Project from "./Project";
import ProjectsData from "./ProjectsData";

function Projects()
{
  return (
    <section>
      <h1 id="home_projects">Projects</h1>
      {
        ProjectsData.map((data) =>
        {
          return <Project key={data.id} data={data} />;
        })
      }
    </section>
  );
}

export default Projects;
