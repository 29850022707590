const factorsData =
  [
    {
      id: "pet_nanny_factor_1",
      imageObject:
        <svg
          width="48"
          height="48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="18" fill="#3A86FF" />
          <path d="M28.6667 18.1665H19.3333C18.4496 18.1676 17.6024 18.5191
            16.9775 19.144C16.3526 19.7689 16.0011 20.6161 16
            21.4998H32C31.9989 20.6161 31.6474 19.7689 31.0225 19.144C30.3976
            18.5191 29.5504 18.1676 28.6667 18.1665Z" fill="white"
          />
          <path d="M16 26.5C16.0011 27.3837 16.3526 28.231 16.9775
            28.8558C17.6024 29.4807 18.4496 29.8323 19.3333
            29.8333H28.6667C29.5504 29.8323 30.3976 29.4807 31.0225
            28.8558C31.6474 28.231 31.9989 27.3837 32
            26.5V22.5H16V26.5ZM20.6667 26.1667C20.6667 26.3644 20.608
            26.5578 20.4981 26.7222C20.3883 26.8867 20.2321 27.0149 20.0494
            27.0905C19.8666 27.1662 19.6656 27.186 19.4716 27.1474C19.2776
            27.1089 19.0994 27.0136 18.9596 26.8738C18.8197 26.7339 18.7245
            26.5557 18.6859 26.3618C18.6473 26.1678 18.6671 25.9667 18.7428
            25.784C18.8185 25.6013 18.9466 25.4451 19.1111 25.3352C19.2755
            25.2253 19.4689 25.1667 19.6667 25.1667C19.9319 25.1667 20.1862
            25.272 20.3738 25.4596C20.5613 25.6471 20.6667 25.9014 20.6667
            26.1667Z" fill="white"
          />
        </svg>,
      titleText: "Financial factors",
      listItemsText: [
        { key: "financial_factor_1", text: "Pet services tend to be expensive, users can't always afford a professional pet sitter" },
        { key: "financial_factor_2", text: "Some pets need extra care, which increases the price" },
      ]
    },
    {
      id: "pet_nanny_factor_2",
      imageObject:
        <svg
          width="48"
          height="48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="18" fill="#3A86FF" />
          <path d="M24 16C22.4178 16 20.871 16.4692 19.5554 17.3482C18.2398
            18.2273 17.2145 19.4767 16.609 20.9385C16.0035 22.4003 15.845
            24.0089 16.1537 25.5607C16.4624 27.1126 17.2243 28.538 18.3431
            29.6569C19.462 30.7757 20.8874 31.5376 22.4393 31.8463C23.9911
            32.155 25.5997 31.9965 27.0615 31.391C28.5233 30.7855 29.7727
            29.7602 30.6518 28.4446C31.5308 27.129 32 25.5823 32 24C31.9977
            21.879 31.1541 19.8455 29.6543 18.3457C28.1545 16.8459 26.121
            16.0023 24 16V16ZM24.6667 23.9193C24.6667 24.0323 24.6381
            24.1435 24.5834 24.2424C24.5287 24.3413 24.4498 24.4247 24.354
            24.4847L21.794 26.0847C21.7196 26.1312 21.6367 26.1625 21.5502
            26.177C21.4637 26.1915 21.3751 26.1888 21.2896 26.169C21.2041
            26.1493 21.1234 26.1129 21.0519 26.0619C20.9805 26.0109 20.9198
            25.9464 20.8733 25.872C20.8268 25.7976 20.7955 25.7147 20.781
            25.6282C20.7665 25.5417 20.7692 25.4531 20.789 25.3676C20.8087
            25.2821 20.8451 25.2014 20.8961 25.1299C20.9471 25.0585 21.0116
            24.9978 21.086 24.9513L23.3333 23.55V20.6667C23.3333 20.4899
            23.4036 20.3203 23.5286 20.1953C23.6536 20.0702 23.8232 20 24
            20C24.1768 20 24.3464 20.0702 24.4714 20.1953C24.5964 20.3203
            24.6667 20.4899 24.6667 20.6667V23.9193Z" fill="white" />
        </svg>,
      titleText: "Time factors",
      listItemsText: [
        { key: "time_factor_1", text: "Pet owners often need to deal with last minute cancellations and it is hard to find a replacement on such short notice" },
        { key: "time_factor_2", text: "Browsing the internet and filtering all the information or calling friends to sit for you is time-consuming" },
      ]
    },
    {
      id: "pet_nanny_factor_3",
      imageObject:
        <svg
          width="48"
          height="48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="24" cy="24" r="18" fill="#3A86FF" />
          <path d="M19.9116 26.3887C19.2085 25.7392 18.6722 24.9299 18.3481
            24.0293C18.024 23.1287 17.9216 22.1633 18.0496 21.2147C18.1762
            20.2529 18.5341 19.3361 19.0927 18.5429C19.6513 17.7497 20.3938
            17.1038 21.2567 16.6605C22.1197 16.2173 23.0772 15.9898 24.0473
            15.9978C25.0174 16.0057 25.9711 16.2487 26.8267 16.706C27.6822
            17.1633 28.4142 17.8212 28.9597 18.6234C29.5053 19.4256 29.8482
            20.3481 29.959 21.3119C30.0699 22.2756 29.9454 23.2519 29.5963
            24.157C29.2471 25.0621 28.6837 25.8691 27.9543 26.5087C27.4846
            26.9125 27.12 27.4243 26.8916 28H24.6663V23.2107C25.0549 23.0733
            25.3916 22.8192 25.6302 22.4832C25.8689 22.1471 25.9979 21.7455
            25.9996 21.3334C25.9996 21.1566 25.9294 20.987 25.8044 20.862C25.6793
            20.7369 25.5098 20.6667 25.333 20.6667C25.1561 20.6667 24.9866
            20.7369 24.8616 20.862C24.7365 20.987 24.6663 21.1566 24.6663
            21.3334C24.6663 21.5102 24.5961 21.6797 24.471 21.8048C24.346
            21.9298 24.1764 22 23.9996 22C23.8228 22 23.6532 21.9298 23.5282
            21.8048C23.4032 21.6797 23.333 21.5102 23.333 21.3334C23.333
            21.1566 23.2627 20.987 23.1377 20.862C23.0127 20.7369 22.8431
            20.6667 22.6663 20.6667C22.4895 20.6667 22.3199 20.7369 22.1949
            20.862C22.0699 20.987 21.9996 21.1566 21.9996 21.3334C22.0013
            21.7455 22.1303 22.1471 22.369 22.4832C22.6077 22.8192 22.9444
            23.0733 23.333 23.2107V28H21.0416C20.7868 27.3871 20.4011 26.8371
            19.9116 26.3887ZM21.333 29.3334V29.54C21.3337 30.1923 21.5931
            30.8176 22.0543 31.2787C22.5154 31.7399 23.1407 31.9993 23.793
            32H24.2063C24.8585 31.9993 25.4838 31.7399 25.945 31.2787C26.4062
            30.8176 26.6656 30.1923 26.6663 29.54V29.3334H21.333Z" fill="white" />
        </svg>,
      titleText: "Physiological factors",
      listItemsText: [
        { key: "physiological_factor_1", text: "Pet owners are afraid to leave their pets with strangers, some of them even suffer from separation anxiety" },
        { key: "physiological_factor_2", text: "It is hard to find someone who is trustworthy and reliable" },
      ]
    }
  ];

export default factorsData;
