import React from "react";

function ProblemStatement({ problemText, solutionText })
{
  return (
    <section>
      <h1>Problem statement</h1>

      <h2>Problem</h2>
      <p>{problemText}</p>

      <h2>Solution</h2>
      <p>{solutionText}</p>
    </section>
  );
}

export default ProblemStatement;
