import React from "react";
import PropTypes from 'prop-types';

function StyleColor({ name, code })
{
    return (
        <div className="captured_style_color">
            <div className="thin_light_border" style={{ backgroundColor: code }} ></div>
            <div className="captured_style_color_text">
                <p>{name}</p>
                <p>{code}</p>
            </div>
        </div>
    );
}

export default StyleColor;

StyleColor.propTypes = {
    name: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
}
