import React from "react";
import Header from "../../Header";
import GeneralInformation from "../GeneralInformation";
import ProjectOverview from "../ProjectOverview";
import ProblemStatement from "../ProblemStatement";
import Julia from "../../images/02_notex/julia.webp";
import storyboard_header from "../../images/02_notex/storyboard_header.webp";
import storyboard_1 from "../../images/02_notex/storyboard_01.webp";
import storyboard_2 from "../../images/02_notex/storyboard_02.webp";
import storyboard_3 from "../../images/02_notex/storyboard_03.webp";
import storyboard_4 from "../../images/02_notex/storyboard_04.webp";
import storyboard_5 from "../../images/02_notex/storyboard_05.webp";
import storyboard_6 from "../../images/02_notex/storyboard_06.webp";
import information_architecture from "../../images/02_notex/information_architecture.webp";
import notex_video from "../../images/02_notex/notex.mp4";
import sell_notes_01_account from "../../images/02_notex/sell_notes_01_account.webp";
import sell_notes_02_upload from "../../images/02_notex/sell_notes_02_upload.webp";
import sell_notes_03_info from "../../images/02_notex/sell_notes_03_info.webp";
import sell_notes_04_publish from "../../images/02_notex/sell_notes_04_publish.webp";
import sell_notes_05_success from "../../images/02_notex/sell_notes_05_success.webp";
import buy_notes from "../../images/02_notex/buy_notes.webp";
import about from "../../images/02_notex/about.webp";
import faq from "../../images/02_notex/faq.webp";
import dashboard from "../../images/02_notex/dashboard.webp";
import ProjectsCarousel from "../ProjectsCarousel";
import Footer from "../../Footer";

function NoteX({ projectIndex })
{
  const aboutText = "NoteX is an online marketplace which allows students to " +
    "trade and exchange notes, study guides, and other learning materials. " +
    "The idea is to provide a platform that can help them with their studies " +
    "and/or enable them to acquire some funds throughout the process.";

  const responsibilitiesText = "Conducting interviews, digital wire framing, high-fidelity " +
    "prototyping, and iterating on designs.";

  const problemText = "Students often experience difficulties during their studies, " +
    "especially when their workload is huge. The issues range from temporary " +
    "glitches to chronic lack of motivation and low productivity. They often " +
    "don't manage to overcome these issues on time which can lead to further complications.";

  const solutionText = "Creating an online platform for trading and exchanging learning " +
    "materials. This platform will help students improve their grades, earn some " +
    "money on the side and protect them from getting overwhelmed with school by " +
    "providing them the essentials for quicker learning. All the uploaded notes " +
    "will be verified by NoteX.";

  return (
    <>
      <Header />

      <main>
        <GeneralInformation projectIndex={projectIndex} />
        <ProjectOverview aboutText={aboutText} responsibilitiesText={responsibilitiesText} />
        <ProblemStatement problemText={problemText} solutionText={solutionText} />

        <section>
          <h1>Research</h1>

          <h2>Interviews</h2>
          <p className="max_width_720px">
            I conducted interviews with potential users in order to understand their needs
            and create a user profile I'm designing for. The primary user group identified
            throughout this research were socially active students and students who work
            part-time jobs to support themselves financially. I interviewed 5 participants,
            3 ladies and 2 gentlemen, all aged between 18 and 25.
          </p>
          <p className="max_width_720px">
            The interviews revealed that the users were already using resources from various
            online platforms and extra help to improve their grades. 4 out of 5 interviewees
            couldn't afford a tutor, therefore they had to look for help online and from
            classmates. However, finding verified documents on the internet is hard and
            time consuming.
          </p>
        </section>

        <section>
          <h1>Define</h1>

          <h2>Persona</h2>
          <p className="max_width_720px">
            Based on the information I gathered, I was able to create a persona that
            represents the target audience of the NoteX online platform. My persona,
            Julia, is a working student. She requires help to improve her grades,
            because she misses class sometimes.
          </p>

          <div id="julia" className="resp_01 w2k">
            <img src={Julia} alt="Julia" />
            <div>
              <h2>Julia, 22</h2>
              <h3>Occupation: Student, part-time waitress</h3>
              <h3>Hometown: Chicago, IL, USA</h3>
              <h3>Housing status: lives with roommates in a rented apartment</h3>
              <p>
                Julia is a student in Chicago, Illinois. She loves the big city, but her schedule
                doesn't allow her to explore it better. To be able to afford her tuition and apartment
                she took a job at a local coffee shop. The remainder of the day she spends studying.
                Sometimes, when she is too tired from work, she misses morning classes.
              </p>
              <hr />
              <div>
                <h3>Frustrations</h3>
                <ul>
                  <li>Missing classes because of work and fatigue</li>
                  <li>Too many long breaks between classes</li>
                  <li>Asking classmates to borrow their notes</li>
                </ul>
              </div>
              <div>
                <h3>Goals</h3>
                <ul>
                  <li>Finding a good balance between her studies and work</li>
                  <li>No more missed classes</li>
                  <li>More time for herself</li>
                </ul>
              </div>
              <q>I don't want to constantly bother my classmates for their notes after I miss a class</q>
            </div>
          </div>
        </section>

        <section>
          <h1>Julia's storyboard</h1>

          <img src={storyboard_header} alt="Storyboard header" />
          <div className="julias_storyboard w2k">
            <img src={storyboard_1} alt="Storyboard 1" />
            <img src={storyboard_2} alt="Storyboard 2" />
            <img src={storyboard_3} alt="Storyboard 3" />
            <img src={storyboard_4} alt="Storyboard 4" />
            <img src={storyboard_5} alt="Storyboard 5" />
            <img src={storyboard_6} alt="Storyboard 6" />
          </div>
        </section>

        <section>
          <h1>Ideate</h1>

          <h2>Information architecture</h2>
          <p className="max_width_720px">
            I created the NoteX website's information architecture using a FigJam template.
            Effective information architecture is key for creating a seamless user journey,
            therefore, it should be organized in a way that best meets the NoteX user's needs.
          </p>

          <img src={information_architecture} alt="Information architecture" />
        </section>

        <section id="notex_ui_mockups">
          <h1>UI mockups</h1>

          <h2>Home page</h2>
          <video className="thin_light_border mh85" autoPlay loop muted>
            <source src={notex_video} type="video/mp4" />
          </video>

          <h2>Selling notes has never been easier</h2>

          <img src={sell_notes_01_account} alt="Create account" className="thin_light_border" />
          <img src={sell_notes_02_upload} alt="Upload notes" className="thin_light_border" />
          <img src={sell_notes_03_info} alt="Add information" className="thin_light_border" />
          <img src={sell_notes_04_publish} alt="Publish notes" className="thin_light_border" />
          <img src={sell_notes_05_success} alt="Successfully published" className="thin_light_border" />

          <h2>Buying notes</h2>
          <img className="thin_light_border" src={buy_notes} alt="Buying notes" />

          <h2>Additional pages</h2>
          <img className="thin_light_border" src={about} alt="About" />
          <img className="thin_light_border" src={faq} alt="FAQ" />
          <img className="thin_light_border" src={dashboard} alt="Dashboard" />

        </section>

        <ProjectsCarousel projectIndex={projectIndex} />
      </main>

      <Footer />
    </>
  );
}

export default NoteX;
