import Header from "../Header";
import Hi from "./Hi";
import Projects from "./Projects";
import Footer from "../Footer";

function App()
{
  return (
    <>
      <Header />
      <main>
        <Hi />
        <Projects />
      </main>
      <Footer />
    </>
  );
}

export default App;
