import React from "react";

function ProjectOverview({ aboutText, responsibilitiesText })
{
  return (
    <section>
      <h1>Project overview</h1>

      <h2>About the project</h2>
      <p>{aboutText}</p>

      <h2>Responsibilities</h2>
      <p>{responsibilitiesText}</p>
    </section>
  );
}

export default ProjectOverview;
