import React from "react";
import colors_and_typography_model from "../../images/04_mango/colors_and_typography_model.webp";
import ColorPurse from "./ColorPurse";

function ColorsAndTypography()
{
  return (
    <>
      <h2>Colors and typography</h2>
      <p>
        I've chosen calm and neutral colors to emphasize the product images and
        to create the "high-fashion and elegant feel" of the brand. Pairing Italiana
        and Libre Franklin font families gives the design a stylish and modern look.
      </p>

      <div id="mango_colors_and_typography">
        <div>
          <div id="mango_colors_and_typography_italiana">
            <span>Italiana</span>
            <span>aA</span>
          </div>
          <div id="mango_colors_and_typography_libre_franklin">
            <span>aA</span>
            <span>Libre Franklin</span>
            <div>
              <p>Light</p>
              <p>Regular</p>
              <p>Medium</p>
            </div>
          </div>
        </div>
        <img src={colors_and_typography_model} alt="Model" className="mh75" />

        <div id="mango_color_purses">
          <ColorPurse fillColor="#e0d3cb" />
          <ColorPurse fillColor="#bc9074" />
          <ColorPurse fillColor="#292d32" />
          <ColorPurse fillColor="#fffcfc" fontColor="#292D32" />
        </div>
      </div>
    </>
  );
}

export default ColorsAndTypography;
