import React from "react";

function Factor({ imageObject, titleText, listItemsText })
{
  return (
    <div>
      {imageObject}
      <h2>{titleText}</h2>
      <ul>
        {listItemsText.map((item) => <li key={item.key}>{item.text}</li>)}
      </ul>
    </div>
  );
}

export default Factor;
