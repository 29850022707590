function makeProjectData(id, title, description, role, duration, tools, imageSrc, path)
{
  return { id, title, description, role, duration, tools, imageSrc, path };
}

const data = [
  makeProjectData
    (
      "project_01",
      "Art corner",
      "Art Gallery mobile application design",
      "UI & UX Design",
      "June - September 2021",
      "Figma, Photoshop",
      require("../images/01_art_gallery/art_gallery.webp"),
      "/art_gallery",
    ),
  makeProjectData
    (
      "project_02",
      "NoteX website design",
      "Study materials exchange website design",
      "UI & UX Design",
      "March - April 2022",
      "Figma, Photoshop",
      require("../images/02_notex/notex.webp"),
      "/notex",
    ),
  makeProjectData
    (
      "project_03",
      "PetNanny",
      "Pet sitter mobile application design",
      "UI & UX Design",
      "April - June 2021",
      "Figma, Photoshop, Illustrator",
      require("../images/03_pet_nanny/pet_nanny.webp"),
      "/pet_nanny",
    ),
  makeProjectData
    (
      "project_04",
      "Mango redesign",
      "Redesign of the Mango fashion store's website",
      "UI & UX Design",
      "February - March 2022",
      "Figma, Photoshop",
      require("../images/04_mango/mango.webp"),
      "/mango",
    ),
  makeProjectData
    (
      "project_05",
      "Captured",
      "Responsive website for stock images",
      "UI Design",
      "October 2021 - January 2022",
      "Figma, Photoshop, Illustrator",
      require("../images/05_captured/captured.webp"),
      "/captured",
    ),
];

export default data;
